import {
  IonButton,
  IonSpinner,
} from "@ionic/react";
import React, { useState } from "react";
import { API_URL } from "../../../actions/settings";
import NotificationPopUp from "../../extras/NotificationPopUp";

interface Props {
  id: string;
  name: string;
}

async function downloadReport(
  token: string,
  id: string,
) {
  return fetch(`${API_URL}/reports/cost_avoidance`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      building: id,
    }),
  }).then(async (res: any) => {
    if (res.ok) {
      return res.blob();
    } else {
      const data = await res.json();
      return {
        error: data.error,
      };
    }
  });
}

const CostAvoidanceReportButton: React.FC<Props> = ({ id, name }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    const tkn: any = localStorage.getItem("token");
    const token = `Token ${tkn.slice(1, -1)}`;
    const res = await downloadReport(token, id,);
    if ("error" in res) {
      NotificationPopUp("error", res["error"], "Error Generating Report");
    } else {
      const url = window.URL.createObjectURL(res);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}_Cost_Avoidance_Report.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
    }
    setLoading(false);
  };

  return (
    <div>
      <IonButton
        id="download-cost-avoidance-report"
        fill="solid"
        size="small"
        color="medium"
        onClick={()=>handleDownload()}
      >
        {loading ? <IonSpinner /> : "Cost Avoidance Report"}
      </IonButton>
    </div>
  );
};

export default CostAvoidanceReportButton;
