import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";

interface Props {
  data: Result[];
}

interface Result {
  property: string;
  savings?: string;
  error?: string;
}

const CostAvoidanceResults: React.FC<Props> = ({ data }) => {
  return (
    <div>
      <IonAccordionGroup>
        {data.map((row: Result, i: Number) => {
          return (
            <IonAccordion key={`cost-avoidance-${i}`}>
              <IonItem slot="header">
                <IonLabel>
                  {row.error ? (
                    <span style={{ color: "red" }}> Error </span>
                  ) : (
                    <span style={{ color: "green" }}>Success </span>
                  )}{" "}
                  : {row.property}
                </IonLabel>
              </IonItem>
              <div slot="content" style={{ color: "black" }}>
                {row.error ? (
                  <p style={{ color: "red" }}>{row.error}</p>
                ) : (
                  <p style={{ color: "black" }}><pre>{JSON.stringify(row.savings, null, 2)}</pre></p>
                )}
              </div>
            </IonAccordion>
          );
        })}
      </IonAccordionGroup>
    </div>
  );
};

export default CostAvoidanceResults;
