import { IonButton, IonIcon, IonRow, IonSpinner } from "@ionic/react"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../../Hooks"
import { selectUser } from "../../../reducers/UserReducer"
import { isStaff } from "../../extras/Functions"
import { refreshCircleOutline } from "ionicons/icons"
import { API_URL } from "../../../actions/settings"
import NotificationPopUp from "../../extras/NotificationPopUp"

interface props{
    page?:string
    setPage:any
}

async function reloadData(id:any,token:any) {

    return fetch(`${API_URL}/energystar/property/${id}/update_cost_avoidance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':token
      }

    })
    .then((res) => {
        if (res.ok) {
            return true;
        } else {
            NotificationPopUp('error',`Could not pull new data.`)
            return false;
        }
      });
   }

const EnergyPerformanceMenu: React.FC<props>= ({page,setPage}) => {
    type Choices = 'home' | 'targets' |'costAvoidance' | 'UtilityBills' | 'degreeDaysRegression'
    const [loading,setLoading] = useState(false)
    let params =  new URLSearchParams(window.location.search);
    const id = params.get('id')
    const user = useAppSelector(selectUser)
    const handleMenuClick = (e:any,page:Choices) =>{
        setPage(page)
        const buttons: NodeListOf<Element>  = document.querySelectorAll(".energy-performance-menu-button")
        buttons.forEach((button:any)=>{
            button.classList.remove('selected')
        })
        e.target.classList.add('selected')

    }

    const handleReloadDash = async () => {
        setLoading(true)
        const token = `Token ${(localStorage.getItem("token")||'').slice(1, -1)}`;
        const res = await reloadData(id,token)
        setLoading(false)
        if(res){
            window.location.reload();
        }
    }

    useEffect(()=>{
        const button = document.querySelector('#energy-performance-home')
        button?.classList.add('selected')
    },[])


    return(
        <IonRow className="energy-performance-menu-buttons ion-justify-content-between" style={{borderBottom:"2px solid grey"}}>
            <div>
            <IonButton className="energy-performance-menu-button" id="energy-performance-home" fill='clear' color="light" onClick={(e)=>handleMenuClick(e,'home')}>Energy Efficiency</IonButton>
            <IonButton className="energy-performance-menu-button" fill='clear' color="light" onClick={(e)=>handleMenuClick(e,'targets')}>Energy Targets</IonButton>
            <IonButton className="energy-performance-menu-button" fill='clear' color="light" onClick={(e)=>handleMenuClick(e,'costAvoidance')}>Cost Avoidance</IonButton>
            <IonButton className="energy-performance-menu-button" fill='clear' color="light" onClick={(e)=>handleMenuClick(e,'UtilityBills')}>Utility Bills</IonButton>
            { isStaff(user) ? <IonButton className="energy-performance-menu-button" fill='clear' color="light" onClick={(e)=>handleMenuClick(e,'degreeDaysRegression')}>Regression</IonButton> : null }

            </div>
            <div>
            {isStaff(user) && page === 'costAvoidance' ?
                <IonButton size="small" color='dark' disabled={loading} title="Update Dashboard Data" onClick={() => handleReloadDash()}>
                    {loading? <IonSpinner name="dots" /> :<IonIcon color="light" size="small" icon={refreshCircleOutline}/>}
                </IonButton>
            : null}
            </div>
        </IonRow>
    )
}

export default EnergyPerformanceMenu