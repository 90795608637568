import React, { useEffect } from "react";
import NotificationPopUp from "../../extras/NotificationPopUp";
import { API_URL } from "../../../actions/settings";
import { IonButton, IonIcon } from "@ionic/react";
import { trashBin } from "ionicons/icons";

interface DegreeDaysRegressionCurrentProps {
  id: string | null | undefined;
  current: any;
  setCurrent: any;
  hidden: boolean;
}

async function currentRegression(id: any, token: string) {
  return fetch(`${API_URL}/buildings/${id}/degree_days_coefficients/current`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        res.json().then((err) => {
          NotificationPopUp("Error", err, "Error Getting Data");
        });
      }
    })
    .catch((error) => {
      NotificationPopUp("Error", error.message, "Error Getting Data");
    });
}

async function deleteRegression(id: any, token: string) {
  return fetch(`${API_URL}/degree_days_coefficients/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  })
    .then((res) => {
      if (res.ok) {
        NotificationPopUp("Success","Regression Values Deleted");
      } else {
        res.json().then((err) => {
          NotificationPopUp("Error", err, "Error Deleting Data");
        });
      }
    })
    .catch((error) => {
      NotificationPopUp("Error", error.message, "Error Deleting Data");
    });
}

const DegreeDaysRegressionCurrent: React.FC<
  DegreeDaysRegressionCurrentProps
> = ({ id, current, setCurrent, hidden }) => {

  const handleDelete = async (id:string) => {
    const token = `Token ${(localStorage.getItem("token") || "").slice(
      1,
      -1
    )}`;

    await deleteRegression(id,token);
    handleCurrentRegression()
  }

  function roundToThree(num:any) {
    if(!num){
      return num
    }
    return Math.round((num + Number.EPSILON) * 1000)/1000;
}

  const handleCurrentRegression = async () => {
    const token = `Token ${(localStorage.getItem("token") || "").slice(
      1,
      -1
    )}`;
    const res = await currentRegression(id, token);
    setCurrent(res);
  };

  useEffect(() => {


    handleCurrentRegression();
    // eslint-disable-next-line
  }, [id]);

  return (
    <div
      hidden={hidden}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        overflowX: "auto",
        margin: "2em",
      }}
    >
      {current ? (
        <table>
          <tr>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
              colSpan={11}
            >
              {" "}
              Current AE2 Values Used{" "}
            </th>
          </tr>
          <tr>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              Base Coefficient
            </th>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              Base Temp(HDD)
            </th>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              HDD Coefficient
            </th>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              Base Temp (CDD)
            </th>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              CDD Coefficient
            </th>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              R - Squared
            </th>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              Adjusted R - Squared
            </th>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              Cross Validated R - Squared
            </th>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              Standard Error
            </th>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              Coefficient of Variation of the Root Mean Square Error
            </th>
            <th
              style={{
                backgroundColor: "#4a4a4a",
                border: "black solid 2px",
                padding: "1em",
              }}
            >
              Delete
            </th>
          </tr>
          <tr>
            <th
              colSpan={11}
              style={{
                backgroundColor: "#838383",
                border: "black solid 2px",
                padding: "0.5em",
              }}
            >
              {" "}
              Electric (kWh){" "}
            </th>
          </tr>
          {current.electric ? (
            <tr>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.electric.baseload_coefficient)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {current.electric.HDD_base_temp}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.electric.HDD_coef)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {current.electric.CDD_base_temp}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.electric.CDD_coef)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.electric.rSquared)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.electric.adjustedRSquared)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.electric.crossValidatedRSquared)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.electric.standardError)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.electric.cvrmse)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d"
                }}
              >
                <IonButton color="danger" size='small' onClick={() => handleDelete(current.electric.uuid)}><IonIcon  slot="icon-only" src={trashBin}></IonIcon></IonButton>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={11}> No Current Values</td>
            </tr>
          )}
          <tr>
            <th
              colSpan={11}
              style={{
                backgroundColor: "#838383",
                border: "black solid 2px",
                padding: "0.5em",
              }}
            >
              {" "}
              Gas (therms){" "}
            </th>
          </tr>
          {current.gas ? (
            <tr>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.gas.baseload_coefficient)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {current.gas.HDD_base_temp}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.gas.HDD_coef)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {current.gas.CDD_base_temp}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.gas.CDD_coef)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.gas.rSquared)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.gas.adjustedRSquared)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.gas.crossValidatedRSquared)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.gas.standardError)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                  padding: "0.5em",
                }}
              >
                {roundToThree(current.gas.cvrmse)}
              </td>
              <td
                style={{
                  boxShadow: "inset 0 0 0 1px #5e5d5d",
                }}
              >
                <IonButton color="danger" size='small' onClick={() => handleDelete(current.gas.uuid)}><IonIcon  slot="icon-only" src={trashBin}></IonIcon></IonButton>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={11}> No Current Values</td>
            </tr>
          )}
        </table>
      ) : (
        <div> 'No Current Regression Data Available'</div>
      )}
    </div>
  );
};

export default DegreeDaysRegressionCurrent;
