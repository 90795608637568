import { IonCheckbox } from "@ionic/react";
import React from "react";

interface Props {
  setSelected: any;
  selected: number;
  regression: number;
}

const SelectCell: React.FC<Props> = ({ setSelected, selected, regression }) => {
  return (
    <div className="center-all">
      <IonCheckbox
        checked={selected === regression}
        onIonChange={(e) => {
          if (e.detail.checked) {
            setSelected(regression);
          } else {
            setSelected(null);
          }
        }}
      ></IonCheckbox>
    </div>
  );
};

export default SelectCell;
