import React from 'react'

interface Props {
  getValue:any,
  row:any,
}

const RankingCell:React.FC<Props> = ({getValue,row}) => {

  return <div>
    <h5 className='center-all'>{getValue()}</h5>
    <div style={{textAlign:'center'}}>
      {row.original.tags ?  row.original.tags.map((tag:any)=>tag) : null}
    </div>
  </div>
}

export default RankingCell