import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import SelectCell from "./SelectCell";
import RankingCell from "./RankingCell";

interface Props {
  data: any;
  type: string;
  selected: any;
  setSelected: any;
}

const DegreeDaysRegressionTable: React.FC<Props> = ({
  data,
  type,
  selected,
  setSelected,
}) => {
  function roundToThree(num:any) {
    if(!num){
      return num
    }
    return Math.round((num + Number.EPSILON) * 1000)/1000;
}
  const columns = [
    {
      header: "Select",
      cell: (props: any) => {
        return (
          <SelectCell
            setSelected={setSelected}
            selected={selected}
            regression={props.row.original.regression}
          />
        );
      },
      size: 75,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "regression",
      header: "Ranking",
      cell: RankingCell,
      size: 150,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "baseload_coefficient",
      header: "Baseload Coefficient",
      cell: (props: any) => (
        <div className="center-all">{roundToThree(props.getValue())}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "HDD_base_temp",
      header: "Base Temp (HDD)",
      cell: (props: any) => (
        <div className="center-all">{props.getValue()}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "HDD_coef",
      header: "HDD Coefficient",
      cell: (props: any) => (
        <div className="center-all">{roundToThree(props.getValue())}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "CDD_base_temp",
      header: "Base Temp (CDD)",
      cell: (props: any) => (
        <div className="center-all">{props.getValue()}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "CDD_coef",
      header: "CDD Coefficient",
      cell: (props: any) => (
        <div className="center-all">{roundToThree(props.getValue())}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "rSquared",
      header: "R - Squared",
      cell: (props: any) => (
        <div className="center-all">{roundToThree(props.getValue())}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "adjustedRSquared",
      header: "Adjusted R - Squared",
      cell: (props: any) => (
        <div className="center-all">{roundToThree(props.getValue())}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "crossValidatedRSquared",
      header: "Cross Validated R - Squared",
      cell: (props: any) => (
        <div className="center-all">{roundToThree(props.getValue())}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "standardError",
      header: "Standard Error",
      cell: (props: any) => (
        <div className="center-all">{roundToThree(props.getValue())}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "cvrmse",
      header: "Coefficient of Variation of the Root Mean Square Error",
      cell: (props: any) => (
        <div className="center-all">{roundToThree(props.getValue())}</div>
      ),
      size: 200,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    }
  ];

  const table = useReactTable({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
  });

  if (data.length <= 0) {
    return null;
  }

  return (
    <div style={{ overflowX: "auto" }}>
      <div
        className="stories-table"
        style={{ width: table.getTotalSize(), marginBottom: "2em" }}
      >
        <div>
          <h3>{type === "electric" ? "Electric (kWh):" : "Gas (therms):"}</h3>
        </div>
        {table.getHeaderGroups().map((headerGroup: any) => (
          <div key={headerGroup.id} className="tr">
            {headerGroup.headers.map((header: any) => {
              return (
                <div
                  key={header.id}
                  className="th"
                  style={{ width: header.getSize() }}

                >
                  {header.column.columnDef.header}
                  <div
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`resizer ${
                      header.column.getIsResizing() ? "isResizing" : ""
                    }`}
                  ></div>
                </div>
              );
            })}
          </div>
        ))}
        {table.getRowModel().rows.map((row: any) => (
          <div
            key={row.id}
            className="tr"
            style={{ width: table.getTotalSize() }}
          >
            {row.getVisibleCells().map((cell: any) => {
              return (
                <div
                  key={cell.id}
                  className="td"
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DegreeDaysRegressionTable;
