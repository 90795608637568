import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLoading,
  IonPopover,
  IonRow,
} from "@ionic/react";
import { createOutline, addCircle, copy, copyOutline } from "ionicons/icons";
import AE2CustomerCampusList from "./AE2CustomerCampusList";
import "./AE2BuildingInfo.css";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import UtilityServiceTable from "./UtilityServiceTable";
import EScoreCard from "../Energystar/EScoreCard";
import MainThumbnailImage from "../MainThumbnailImage";
import {
  API_URL,
  REACT_APP_AWS_BUCKET_NAME,
  REACT_APP_AWS_REGION,
} from "../../actions/settings";
import { useEffect } from "react";
import DegreeDaysTable from "./AnalyticsSection/DegreeDaysTable";
import { isStaff } from "../extras/Functions";
import QuicksightEmbed from "../extras/QuicksightEmbed";
import EnergyStarScoreLineGraph from "../Energystar/EnergyStarScoreLineGraph";
import Download8020InvoiceButton from "./BuildingPageComponents/Download8020InvoiceButton";
import DownloadBenchmarkReportButton from "./BuildingPageComponents/DownloadBenchmarkReportButton";
import CostAvoidanceReportButton from "./BuildingPageComponents/CostAvoidanceReportButton";
interface props {
  item: any;
}

async function getDashUrl(token: string) {
  return fetch(
    `${API_URL}/buildings/getDashUrl/adc98be6-7953-4206-acaf-bff153934807`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    }
  ).then((data) => data.json());
}

async function getStartDate() {
  return fetch(`${API_URL}/energystar_score_start_date`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((data) => data.json());
}

const AE2BuildingInfo: React.FC<props> = ({ item }) => {
  const user = useAppSelector(selectUser);
  const role = user.role?.name;

// eslint-disable-next-line
  const handleDash = async (item: any) => {
    const dashboard = new QuicksightEmbed();
    await dashboard.init();
    const token = localStorage.getItem("token") || "";
    const res = await getDashUrl(`Token ${token.slice(1, -1)}`);
    const date = await getStartDate();
    dashboard.changeSettings({
      url: res[0],
      container: `#score-dashboard`,
      resizeHeight: true,
      height: "500px",
    });
    dashboard.setParameters({
      BuildingUuid: item.uuid,
      StartDate: date.date,
    });
    await dashboard.embedDash();
  };

  useEffect(() => {
    // if (item) {
    //   handleDash(item);
    // }
  }, [item]);

  const copyUUID = () => {
    navigator.clipboard.writeText(item.uuid);
    const popup = document.getElementById("copied");
    popup?.classList.toggle("show");
    setTimeout(() => {
      const popup = document.getElementById("copied");
      popup?.classList.toggle("show");
    }, 3000);
  };

  return (
    <>
      {!item ? (
        <IonLoading
          cssClass="my-custom-class"
          isOpen={!item}
          message={"Loading..."}
          spinner="bubbles"
          duration={10000}
        />
      ) : (
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeLg="9">
              <IonCol size="12">
                <IonRow>
                  <IonCol className="pc-only" size="12" sizeLg="2">
                    <MainThumbnailImage
                      src={`https://${REACT_APP_AWS_BUCKET_NAME}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/thumbnails/pics/${item.uuid}-building-img`}
                    />
                  </IonCol>
                  <IonCol size="12" sizeLg="10">
                    <IonRow className="ion-justify-content-center ae2-building-name">
                      <h1 className="align-bottom">
                        <span>{item.building_name}</span>
                        <IonButton
                          hidden={!(role === "Supervisor" || role === "Admin")}
                          fill="clear"
                          color="light"
                          slot="icon-only"
                          href={`/building/${item.uuid}/edit`}
                        >
                          <IonIcon
                            slot="icon-only"
                            src={createOutline}
                          ></IonIcon>
                        </IonButton>
                      </h1>
                    </IonRow>
                    <IonRow className="ion-justify-content-center mobile-only thumbnail-mobile">
                      <IonCol size="12">
                        <MainThumbnailImage
                          src={`https://${REACT_APP_AWS_BUCKET_NAME}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/thumbnails/pics/${item.uuid}-building-img`}
                        />
                      </IonCol>
                    </IonRow>
                    <h2 className="ae2-building-subheading">
                      Building Information:
                    </h2>
                    <IonRow>
                      <IonCol
                        className="basic-building-info"
                        size="12"
                        sizeLg="6"
                      >
                        {isStaff(user) ? (
                          <span className="popup">
                            <span className="popuptext-building" id="copied">
                              Copied
                            </span>
                            <IonButton
                              className="clipboardButton"
                              color="light"
                              slot="icon-only"
                              fill="clear"
                              onClick={copyUUID}
                            >
                              {" "}
                              <IonIcon md={copy} ios={copyOutline} />
                              COPY ID
                            </IonButton>{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        <div>
                          <span>Customer</span>:{" "}
                          <span className="customer-name">
                            <a
                              href={`/?id=${item.cust_uuid.uuid}&sec=customers`}
                            >
                              {item.cust_uuid.cust_name}
                            </a>
                          </span>
                        </div>
                        {item.campus_uuid ? (
                          <div>
                            <span>Campus</span>:
                            <span className="campus-name">
                              {" "}
                              <a
                                href={`/?id=${item.campus_uuid.uuid}&sec=campuses`}
                              >
                                {" "}
                                {item.campus_uuid.campus_name}
                              </a>
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <span>Description</span>: {item.building_description}
                        </div>
                        <div>
                          <span>Address</span>: {item.building_address}{" "}
                          {item.building_address2 ? item.building_address : ""}
                        </div>
                        <div>
                          {item.building_city}, {item.building_state},{" "}
                          {item.building_zipcode}
                        </div>
                        <div>
                          <span>County</span>: {item.building_county}
                        </div>
                      </IonCol>
                      <IonCol
                        className="basic-building-info"
                        size="12"
                        sizeLg="6"
                      >
                        <div>
                          <span>Building Incentive</span>:{" "}
                          {item.building_incentive
                            ? "$" + item.building_incentive.toLocaleString("en")
                            : "None"}
                        </div>
                        <div>
                          <span>Primary Function</span>:{" "}
                          {item.building_type?.name}
                        </div>
                        {item.building_sub_type ? (
                          <div>
                            <span>Property Sub-Type</span>:{" "}
                            {item.building_sub_type.sub_type_name}
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <span>Gross Floor Area</span>:{" "}
                          {item.building_sq_ft.toLocaleString("en")} ft
                          <sup>2</sup>
                        </div>
                        <div>
                          <span>Fan Cycling</span>:{" "}
                          {item.building_fan_cycle.toLocaleString("en")}%{" "}
                          {item.building_fan_cycle ? (
                            <sup title="Fan Cycle Start Date">
                              ( {item.fc_start_date})
                            </sup>
                          ) : (
                            ""
                          )}{" "}
                        </div>
                        <div>
                          <span>Campus Optimizer</span>:{" "}
                          {item.campus_optimizer ? "Yes" : "No"}{" "}
                          {item.campus_optimizer ? (
                            <sup title="Campus Optimizer Start Date">
                              ( {item.co_start_date})
                            </sup>
                          ) : (
                            ""
                          )}{" "}
                        </div>
                        <div>
                          <span>FOA</span>: {item.foa ? "Yes" : "No"}{" "}
                        </div>
                        <div>
                          <span>KPI</span>: {item.kpi ? "Yes" : "No"}{" "}
                        </div>
                        <div>
                          <span>Total Project Fee</span>: {item.project_fee ? `$${item.project_fee.toLocaleString("en")}` : "None"}{" "}
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
                {isStaff(user) ? (
                  <IonRow>
                    <IonButtons>
                      <IonButton
                        fill="solid"
                        color="medium"
                        size="small"
                        href={`/building/monthly_performance/${item.uuid}`}
                      >
                        {" "}
                        Monthly Building Performance
                      </IonButton>
                      {item.baseline ? (
                        <>
                          {/* <IonButton fill='solid' href={`/energystar/property/${item.estar.id}/template`} size="small" color="medium"> Edit Report Template</IonButton> */}
                          {/* <IonButton
                            fill="solid"
                            href={`/energystar/property/${item.baseline.property.id}/report`}
                            size="small"
                            color="medium"
                          >
                            {" "}
                            Report
                          </IonButton> */}
                          <IonButton
                            id="reports-buttons"
                            fill="solid"
                            size="small"
                            color="medium"
                          >
                            Reports
                          </IonButton>
                          <IonPopover
                            trigger="reports-buttons"
                            size="auto"
                            side="bottom"
                            alignment="start"
                            showBackdrop={false}
                          >
                            <IonContent  style={{"--background":"#6C7C84", margin: "1rem" }}>
                              <div style={{width:'100%', textAlign:'center', padding:'0.5rem'}}>
                              <DownloadBenchmarkReportButton id={item.uuid} name={item.building_name} />
                              <CostAvoidanceReportButton id={item.uuid} name={item.building_name} />
                              </div>
                            </IonContent>
                          </IonPopover>
                        </>
                      ) : (
                        ""
                      )}
                      <Download8020InvoiceButton id={item.uuid} name={item.building_name}/>
                    </IonButtons>
                  </IonRow>
                ) : (
                  ""
                )}
              </IonCol>
              {item.baseline ? (
                <IonRow>
                  <IonCol size="12" sizeXl="5" className="score-dashboard-container">
                    <h2 className="align-bottom utility-title">
                      <span>Energy Star Score Dashboard</span>
                    </h2>
                    {/* <div id="score-dashboard"></div> */}
                    <EnergyStarScoreLineGraph id={item.uuid} />
                  </IonCol>
                  <IonCol sizeXl="7" hidden={!isStaff(user)}>
                    <h2 className="align-bottom utility-title">
                      <span>Annual Building Performance:</span>
                    </h2>
                    <DegreeDaysTable id={item.uuid} type="building" />
                  </IonCol>
                </IonRow>
              ) : (
                ""
              )}
            </IonCol>
            {item.baseline ? (
              <IonCol className="score-container" size="12" sizeMd="3">
                <EScoreCard
                  property={item.baseline}
                  ae2={true}
                  building_id={item.uuid}
                ></EScoreCard>
                <IonRow className="ion-justify-content-center">
                  <div>
                    <h4>Energy Utilization Index:</h4>
                    <div className="value">
                      {item.baseline.score.site_eui
                        ? item.baseline.score.site_eui
                        : "N/A"}
                    </div>
                  </div>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <div>
                    <h4>Price Per Square Foot</h4>
                    <div className="value">
                      $
                      {Math.round(
                        (Number(item.baseline.score.energycost) /
                          Number(item.building_sq_ft)) *
                          100
                      ) / 100}
                      /sf
                    </div>
                  </div>
                </IonRow>
              </IonCol>
            ) : (
              ""
            )}
          </IonRow>
          <div>
            { isStaff(user) ?
            <IonRow>
              <IonCol>
                <h2 className="align-bottom utility-title">
                  <span>Utility Services:</span>
                  <IonButton
                    hidden={!(role === "Supervisor" || role === "Admin")}
                    size="small"
                    fill="clear"
                    color="light"
                    slot="icon-only"
                    href={`/building/${item.uuid}/utility_service`}
                  >
                    <IonIcon slot="icon-only" src={addCircle}></IonIcon>
                  </IonButton>
                </h2>
                <UtilityServiceTable utility_services={item.utility_services} />
              </IonCol>
            </IonRow> : null
      }
            <IonRow>
              <IonCol hidden={item.campus_uuid == null}>
                <h2 className="campus-title">
                  <span>Campus:</span>{" "}
                </h2>
                {item.campus_uuid == null ? (
                  <p>Building not associated with any campus.</p>
                ) : (
                  <AE2CustomerCampusList
                    campuses={[item.campus_uuid]}
                    buildings={item.campus_buildings}
                  />
                )}
              </IonCol>
            </IonRow>
          </div>
        </IonGrid>
      )}
    </>
  );
};

export default AE2BuildingInfo;
