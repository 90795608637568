import { IonButton } from "@ionic/react";
import { API_URL } from "../../../actions/settings";
import { useState } from "react";
import DegreeDaysRegressionTable from "./DegreeDaysRegressionTable";
import NotificationPopUp from "../../extras/NotificationPopUp";
import useToggle from "../../../utils/useToggle";
import DegreeDaysRegressionCurrent from "./DegreeDaysRegressionCurrent";

interface Props {
  id?: string | null;
}

async function getRegressions(id: any, token: string) {
  return fetch(`${API_URL}/buildings/${id}/degree_days_coefficients`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        res.json().then((err) => {
          NotificationPopUp("Error", err, "Error Getting Regressions");
        });
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

async function saveRegressions(id: any, body: any, token: string) {
  return fetch(`${API_URL}/buildings/${id}/degree_days_coefficients`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => {
      if (res.ok) {
        NotificationPopUp(
          "Success",
          "Selected Regression is saved and will be used for future calculations.",
          "Data Saved"
        );
        return res.json();
      } else {
        res.json().then((err) => {
          NotificationPopUp("Error", err, "Error Saving Data");
        });
      }
    })
    .catch((error) => {
      NotificationPopUp("Error", error.message, "Error Saving Data");
    });
}

const DegreeDaysRegression: React.FC<Props> = ({ id }) => {
  const [data, setData] = useState({ electric: [], gas: [] });
  const [current, setCurrent] = useState<any>({ electric: null, gas: null });
  const [electricSelected, setElectricSelected] = useState<any>(null);
  const [gasSelected, setGasSelected] = useState<any>(null);
  const [loading, toggleLoading] = useToggle(false);
  const [saving, setSaving] = useToggle(false);
  const [currentTable, toggleCurrentTable] = useToggle(true);

  const handleRegressions = async () => {
    toggleLoading();
    const token = `Token ${(localStorage.getItem("token") || "").slice(1, -1)}`;
    const res = await getRegressions(id, token);
    if (res) {
      setData(res);
    }
    toggleLoading();
  };

  const handleSave = async () => {
    if (!electricSelected && !gasSelected) {
      NotificationPopUp("Error", "No Regressions Selected");
    } else {
      setSaving();
      const electric = data["electric"].filter(
        (e: any) => e.regression === electricSelected
      )[0];
      const gas = data["gas"].filter(
        (e: any) => e.regression === gasSelected
      )[0];

      const save_data = {
        electric: electric,
        gas: gas,
      };
      const token = `Token ${(localStorage.getItem("token") || "").slice(
        1,
        -1
      )}`;
      const res = await saveRegressions(id, save_data, token);
      setCurrent(res);
      setSaving();
    }
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h1> Degree Days Regression</h1>
        <IonButton
          onClick={() => toggleCurrentTable()}
          fill="outline"
          shape="round"
          color={"light"}
        >
          {" "}
          {currentTable ? "View Current Data" : "Hide Current Data"}{" "}
        </IonButton>
        <DegreeDaysRegressionCurrent
          hidden={currentTable}
          id={id}
          current={current}
          setCurrent={setCurrent}
        />
        <p>
          Press the button to run the regressions using degreedays.net. The
          results will show in order based on ranking.
        </p>
        <p>
          Select which one you want to use for each energy type and save it.
        </p>
        <div>
          <IonButton onClick={() => handleRegressions()} disabled={loading}>
            {loading ? "running..." : "Run Regressions"}
          </IonButton>
        </div>
        <div>
          <IonButton
            onClick={() => handleSave()}
            color="success"
            hidden={data["electric"].length <= 0}
            disabled={saving}
          >
            {saving ? "saving..." : "Save"}
          </IonButton>
        </div>
      </div>
      <div>
        {/* Table 1 */}
        <DegreeDaysRegressionTable
          data={data["electric"]}
          type="electric"
          selected={electricSelected}
          setSelected={setElectricSelected}
        />
        {/* Table 2 */}
        <DegreeDaysRegressionTable
          data={data["gas"]}
          type="gas"
          selected={gasSelected}
          setSelected={setGasSelected}
        />
      </div>
    </div>
  );
};

export default DegreeDaysRegression;
